import React, { Component, Fragment } from 'react';
import
    { Landmarks
    , analyzeLandmarks
    , findFacePositionAndAngle
    } from './Transfer/Landmarks';
import
    { MappedAttributes
    , mapAttributes
    , attrOf
    , MappedAttributesKey
    } from './Transfer/Attributes';
import { Files } from './Transfer/Files';

import { ReactSVG } from 'react-svg';
//import Icons from "components/hair.svg"; // Path to your icons.svg
import PropTypes from 'prop-types';


type FaceProps =
    { landmarks: Landmarks
    , attributes : MappedAttributes
    , files : Files
    , showPoints : boolean
    , showCamera : boolean
    , showAvatar : boolean
    , width : number
    , height : number
    }

const FACE_WIDTH = 168;
const FACE_HEIGHT = 168;

class Face extends Component<FaceProps> {

    render() {
        const {
            landmarks,
            attributes,
            files,
            showPoints,
            showCamera,
            showAvatar,
            width, height
        } = this.props;

        if (!landmarks.landmarks.length) return (<div></div>);

        const ll = landmarks.landmarks;
        const l = analyzeLandmarks(width, height, landmarks);
        const a = attributes;
        const face =
            findFacePositionAndAngle(l.leftEar, l.rightEar, l.chinPoint);

        const faceRotAngle = face.angle / (Math.PI/180);
        const faceRotX = face.center[0] + 5;
        const faceRotY = face.center[1] + 5;
        const faceScale = face.width / FACE_WIDTH * 2.5;
        const faceShiftX = FACE_WIDTH * faceScale * 0.05;
        const hairShiftY = 45 / 2.5 * faceScale;
        const hairShiftX = faceShiftX * 1.6;
        const noseShiftX = 10 * faceScale;
        const noseShiftY = 10 * faceScale;
        const browShiftY = 10 * faceScale;
        const browShiftX = face.width / FACE_WIDTH * 3;
        const transition = 'transition: all 700ms ease-in-out';
        const mouthShiftY = 0;
        const isMouthOpen = ((l.mouthLowPoint[1] - l.mouthTopPoint[1]) / 2.5 * faceScale) >= 60;
        const isRightEyeClosed = ll[46][1] - ll[44][1] < (1 * faceScale);
        const isLeftEyeClosed = ll[40][1] - ll[38][1] < (1 * faceScale);

        const styles =
            { face : `${transition};transform: translate(${face.center[0]-faceShiftX}px,${face.center[1]}px) scale(${faceScale}) rotate(${faceRotAngle}deg)`
            , hair : `${transition};transform: translate(${face.center[0]-hairShiftX}px,${face.center[1]-hairShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg)`
            , nose : `${transition};transform: translate(${l.noseRoot[0]-noseShiftX}px,${l.noseRoot[1]+noseShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg)`
            , eyeL : `${transition};transform: translate(${l.leftEyeCenter[0]}px,${l.leftEyeCenter[1]+40}px) scale(${faceScale}) rotate(${faceRotAngle}deg)`
            , eyeR : `${transition};transform: translate(${l.rightEyeCenter[0]}px,${l.rightEyeCenter[1]+40}px) scale(${faceScale}) rotate(${faceRotAngle}deg)`
            , browL: `${transition};transform: translate(${l.leftEyeCenter[0]}px,${l.leftEyeBrow[0][1]+browShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg`
            , browR: `${transition};transform: translate(${l.rightEyeCenter[0]}px,${l.rightEyeBrow[0][1]+browShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg`
            , upperLip: `${transition};transform: translate(${l.mouthTopPoint[0]}px,${l.mouthTopPoint[1]+mouthShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg`
            , lowerLip: `${transition};transform: translate(${l.mouthLowPoint[0]}px,${l.mouthLowPoint[1]+mouthShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg`
            , openMouth: `${transition};transform: translate(${l.mouthTopPoint[0]}px,${l.mouthTopPoint[1]+mouthShiftY}px) scale(${faceScale}) rotate(${faceRotAngle}deg`
        };

        /* const faceStyle = {
            transform: `translate(${face.center[0] - FACE_WIDTH/2}px,${face.center[1] - FACE_HEIGHT/2}px) scale(${faceScale}) rotate(${faceRotAngle}deg)`
        } */

        const variant = 1;

        return (<Fragment>
                { !showCamera && <svg className="abs"
                        width={width}
                        height={height}
                        xmlns="http://www.w3.org/2000/svg">
                            <rect x="0" y="0" width={width} height={height} fill="white"></rect>
                </svg> }
                { showAvatar && <div id="avatar" className="abs">
                <ReactSVG
                    src={`components/split_components/hair/${files.hair}.svg`}
                    beforeInjection={svg => {
                        svg.classList.add('abs');
                        svg.classList.add('face-part');
                        svg.classList.add('face-hair');
                        let path = svg.querySelector('path');
                        if (path) {
                            path.style.fill = files.hairColor;
                        } else {
                            let ellipse = svg.querySelector('ellipse');
                            ellipse.style.fill = files.hairColor;
                        }
                        svg.setAttribute('style', styles.hair);
                    }}
                />
                <ReactSVG
                    src={`components/split_components/face/${files.face}.svg`}
                    beforeInjection={svg => {
                        svg.classList.add('abs');
                        svg.classList.add('face-part');
                        svg.classList.add('face-shape');
                        svg.setAttribute('style', styles.face);
                    }}
                />
                { files.additions.eyeglasses && <ReactSVG
                        src={`components/split_components/face/Eyeglasses.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-shape');
                            svg.setAttribute('style', styles.face);
                        }}
                    />
                }
                { files.additions.doubleChin && <ReactSVG
                        src={`components/split_components/face/Double_Chin.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-shape');
                            svg.setAttribute('style', styles.face);
                        }}
                    />
                }
                { !isLeftEyeClosed ?
                    (<ReactSVG
                        src={`components/split_components/eye/${files.eye}.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-eye');
                            svg.setAttribute('style', styles.eyeL);
                        }}
                    />)
                   : (<ReactSVG
                        src={`components/split_components/eye/eye_closed.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-eye');
                            svg.setAttribute('style', styles.eyeL);
                        }}
                    />)
                }
                { files.additions.bags && <ReactSVG
                        src={`components/split_components/eye/Bags_Under_Eyes.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-shape');
                            svg.setAttribute('style', styles.eyeL);
                        }}
                    />
                }
                { !isRightEyeClosed ?
                    (<ReactSVG
                        src={`components/split_components/eye/${files.eye}.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-eye');
                            svg.setAttribute('style', styles.eyeR);
                        }}
                    />) :
                    (<ReactSVG
                        src={`components/split_components/eye/eye_closed.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-eye');
                            svg.setAttribute('style', styles.eyeR);
                        }}
                    />)
                }
                { files.additions.bags && <ReactSVG
                        src={`components/split_components/eye/Bags_Under_Eyes.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-shape');
                            svg.setAttribute('style', styles.eyeR);
                        }}
                    />
                }
                <ReactSVG
                    src={`components/split_components/eyebrow/${files.eyeBrow}.svg`}
                    beforeInjection={svg => {
                        svg.classList.add('abs');
                        svg.classList.add('face-part');
                        svg.classList.add('face-eyebrow');
                        svg.setAttribute('style', styles.browL);
                    }}
                />
                <ReactSVG
                    src={`components/split_components/eyebrow/${files.eyeBrow}.svg`}
                    beforeInjection={svg => {
                        svg.classList.add('abs');
                        svg.classList.add('face-part');
                        svg.classList.add('face-eyebrow');
                        svg.setAttribute('style', styles.browR);
                    }}
                />
                <ReactSVG
                    src={`components/split_components/nose/${files.nose}.svg`}
                    beforeInjection={svg => {
                        svg.classList.add('abs');
                        svg.classList.add('face-part');
                        svg.classList.add('face-nose');
                        svg.setAttribute('style', styles.nose);
                    }}
                />
                { files.additions.mustache && <ReactSVG
                        src={`components/split_components/Mustache.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-shape');
                            svg.setAttribute('style', styles.upperLip);
                        }}
                    />
                }
                { files.additions.goatee && <ReactSVG
                        src={`components/split_components/Goatee.svg`}
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-shape');
                            svg.setAttribute('style', styles.lowerLip);
                        }}
                    />
                }
                { !isMouthOpen ? (<Fragment>
                    <ReactSVG
                        src="components/split_components/upper_lip.svg"
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-mouth');
                            svg.setAttribute('style', styles.upperLip);
                        }}
                    />
                    <ReactSVG
                        src="components/split_components/lower_lip.svg"
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-mouth');
                            svg.setAttribute('style', styles.lowerLip);
                        }}
                    /></Fragment>) : (<ReactSVG
                        src="components/split_components/mouth_open.svg"
                        beforeInjection={svg => {
                            svg.classList.add('abs');
                            svg.classList.add('face-part');
                            svg.classList.add('face-mouth');
                            svg.setAttribute('style', styles.openMouth);
                        }}
                    />)
                }
                </div> }
                    { showPoints && <svg className="abs"
                        width={width}
                        height={height}
                        xmlns="http://www.w3.org/2000/svg">
                        {
                            landmarks.landmarks.map((pos : [ number, number ], index : number) => (
                                <circle key={index} cx={pos[0]} cy={pos[1]} r="5" fill="black" />
                            ))
                        }
                        <circle cx={l.leftEar[0]} cy={l.leftEar[1]} r="5" fill="red" />
                        <circle cx={l.rightEar[0]} cy={l.rightEar[1]} r="5" fill="red" />
                        <circle cx={l.leftEyeCenter[0]} cy={l.leftEyeCenter[1]} r="5" fill="green" />
                        <circle cx={l.rightEyeCenter[0]} cy={l.rightEyeCenter[1]} r="5" fill="green" />
                        <circle cx={l.mouthTopPoint[0]} cy={l.mouthTopPoint[1]} r="5" fill="blue" />
                        <circle cx={l.mouthLeftPoint[0]} cy={l.mouthLeftPoint[1]} r="5" fill="cyan" />
                        <circle cx={l.mouthLowPoint[0]} cy={l.mouthLowPoint[1]} r="5" fill="blue" />
                        <circle cx={l.mouthRightPoint[0]} cy={l.mouthRightPoint[1]} r="5" fill="cyan" />
                        <circle cx={l.noseTip[0]} cy={l.noseTip[1]} r="5" fill="yellow" />
                        <circle cx={l.noseRoot[0]} cy={l.noseRoot[1]} r="5" fill="yellow" />
                        <circle cx={l.leftEyeBrow[0][0]} cy={l.leftEyeBrow[0][1]} r="5" fill="brown" />
                        <circle cx={l.leftEyeBrow[1][0]} cy={l.leftEyeBrow[1][1]} r="5" fill="brown" />
                        <circle cx={l.rightEyeBrow[0][0]} cy={l.rightEyeBrow[0][1]} r="5" fill="brown" />
                        <circle cx={l.rightEyeBrow[1][0]} cy={l.rightEyeBrow[1][1]} r="5" fill="brown" />
                        <circle cx={l.chinPoint[0]} cy={l.chinPoint[1]} r="5" fill="aqua" />
                        <rect x={face.center[0]} y={face.center[1]} width="10" height="10" transform={`rotate(${faceRotAngle} ${faceRotX} ${faceRotY})`} fill="chocolate" />
                  </svg> }
                { /* showAttributes && <svg className="abs"
                    width={width}
                    height={height}
                    xmlns="http://www.w3.org/2000/svg">
                    { (Object.keys(a) as Array<MappedAttributesKey>).map((attr : MappedAttributesKey, index : number) => (
                            <text x={0} y={index * 20} key={attr}>{attr} &gt; {attrOf(a, attr)}</text>
                        ))
                    }
                    </svg>
                */ }
                { /* <img src="components/split_components/hair/hair_female_alt1.svg" style={faceStyle} /> */ }
            </Fragment>);
    }

}

export default Face;
