import { useService } from './Service';
import { ImageRequest } from './ImageRequest';

const MAPPING : Array<MappedAttributesKey> = ["5_o_Clock_Shadow", "Arched_Eyebrows", "Attractive", "Bags_Under_Eyes", "Bald", "Bangs", "Big_Lips", "Big_Nose", "Black_Hair", "Blond_Hair", "Blurry", "Brown_Hair", "Bushy_Eyebrows", "Chubby", "Double_Chin", "Eyeglasses", "Goatee", "Gray_Hair", "Heavy_Makeup", "High_Cheekbones", "Male", "Mouth_Slightly_Open", "Mustache", "Narrow_Eyes", "No_Beard", "Oval_Face", "Pale_Skin", "Pointy_Nose", "Receding_Hairline", "Rosy_Cheeks", "Sideburns", "Smiling", "Straight_Hair", "Wavy_Hair", "Wearing_Earrings", "Wearing_Hat", "Wearing_Lipstick", "Wearing_Necklace", "Wearing_Necktie", "Young"];

export type MappedAttributes = {
    '5_o_Clock_Shadow' : number,
    'Arched_Eyebrows': number,
    'Attractive': number,
    'Bags_Under_Eyes': number,
    'Bald': number,
    'Bangs': number,
    'Big_Lips': number,
    'Big_Nose': number,
    'Black_Hair': number,
    'Blond_Hair': number,
    'Brown_Hair': number,
    'Blurry': number,
    'Bushy_Eyebrows': number,
    'Chubby': number,
    'Double_Chin': number,
    'Eyeglasses': number,
    'Goatee': number,
    'Gray_Hair': number,
    'Heavy_Makeup': number,
    'High_Cheekbones': number,
    'Male': number,
    'Mouth_Slightly_Open': number,
    'Mustache': number,
    'Narrow_Eyes': number,
    'No_Beard': number,
    'Oval_Face': number,
    'Pale_Skin': number,
    'Pointy_Nose': number,
    'Receding_Hairline': number,
    'Rosy_Cheeks': number,
    'Sideburns': number,
    'Smiling': number,
    'Straight_Hair': number,
    'Wavy_Hair': number,
    'Wearing_Earrings': number,
    'Wearing_Hat': number,
    'Wearing_Lipstick': number,
    'Wearing_Necklace': number,
    'Wearing_Necktie': number,
    'Young': number
}

export type MappedAttributesKey = keyof MappedAttributes;

export interface Attributes {
    attributes: [number]
}

export function useAttributesService(body: ImageRequest) {
    return useService<Attributes, ImageRequest>('/get_face_attributes', body);
}

export function mapAttributes(attrs : Attributes): MappedAttributes {
    return MAPPING.reduce(
        ((mapped, attr, index) => {
            mapped[attr] = attrs.attributes[index]
            return mapped;
        })
    , {} as MappedAttributes);
}

export function defaultMapped(): MappedAttributes {
    return MAPPING.reduce(
        ((mapped, attr) => {
            mapped[attr] = 0;
            return mapped;
        })
    , {} as MappedAttributes);
}

export function loadAttributes(from: MappedAttributes): MappedAttributes {
    return MAPPING.reduce(
        ((mapped, attr) => {
            mapped[attr] = from[attr];
            return mapped;
        })
    , {} as MappedAttributes);
}

export function testAttributes() : MappedAttributes {
    let defaultOnes = defaultMapped();
    defaultOnes.Male = 0.2;
//    defaultOnes.Blond_Hair = 0.7;
    defaultOnes.Bushy_Eyebrows = 0.9;
    defaultOnes.Bags_Under_Eyes = 0.9;
    defaultOnes.Goatee = 0.9;
    // defaultOnes.Double_Chin = 0.9;
    // defaultOnes.Bags_Under_Eyes = 0.7;
    return defaultOnes;
}


export function attrOf(obj: MappedAttributes, key: MappedAttributesKey) {
    return obj[key];
}

export const allKeys : Array<MappedAttributesKey> = MAPPING;
