import { Attributes, MappedAttributes, mapAttributes, testAttributes, defaultMapped } from './Transfer/Attributes';

export type State =
    { attributes : MappedAttributes
    , width : number
    , height : number
    , showPoints : boolean
    , showCamera : boolean
    , showAvatar : boolean
    , focusWidth : number
    , focusHeight : number
    , refreshPeriod : number
    };


export const defaultState : State =
    { showPoints : false
    , showCamera : false
    , showAvatar : true
    , focusWidth : 0.8
    , focusHeight : 0.6
    , attributes : defaultMapped()
    , refreshPeriod : 2000
    , width : 1280
    , height : 768
    }
