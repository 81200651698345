import { useService } from './Service';
import { ImageRequest } from './ImageRequest';

type pos = [number,number];

export interface Landmarks {
  landmarks: pos[]
}

/* export interface LandmarksGrouped {
  leftEar : [ number, number ],
  rightEar : [ number, number ],
  chin : pos[],
  topLip : pos[],
  lowLip : pos[],
  noseLine : pos[],
  noseForm : pos[],
  leftEye : pos[],
  rightEye : pos[],
  leftEyebrow : pos[],
  rightEyebrow : pos[]
} */

export interface LandmarksAnalyzed
  { leftEar : pos
  , rightEar : pos
  , leftEyeCenter : pos
  , rightEyeCenter : pos
  , mouthTopPoint : pos
  , mouthLowPoint : pos
  , mouthLeftPoint : pos
  , mouthRightPoint : pos
  , noseTip : pos
  , noseRoot : pos
  , leftEyeBrow: [ pos, pos ]
  , rightEyeBrow: [ pos, pos]
  , chinPoint : pos
  }

export function useLandmarksService(body: ImageRequest) {
  return useService<Landmarks, ImageRequest>('/get_face_landmarks', body);
}

function findCenter(width : number, height: number, points: pos[]): pos {
  if (!points.length) return [0, 0];
  const vals = points.reduce(
      (prev, point) => {
        return { min :
            [ point[0] < prev.min[0] ? point[0] : prev.min[0]
            , point[1] < prev.min[1] ? point[1] : prev.min[1]
            ]
        , max :
            [ point[0] > prev.max[0] ? point[0] : prev.max[0]
            , point[1] > prev.max[1] ? point[1] : prev.max[1]
            ]
        }
      }
  , { min: [width, height], max : [0, 0] })
  return [
      vals.min[0] + ((vals.max[0] - vals.min[0]) / 2)
      , vals.min[1] + ((vals.max[1] - vals.min[1]) / 2)
  ];
}

type FaceStats =
  { center : pos
  , angle : number
  , width : number }

export function findFacePositionAndAngle(leftEar : pos, rightEar : pos, chin: pos) : FaceStats {
  const distX = rightEar[0] - leftEar[0];
  const distY = rightEar[1] - leftEar[1];
  const center : pos =
      [ leftEar[0] + (distX / 2)
      , leftEar[1] + (distY / 2)
      ];
  const angle = Math.atan2(distY, distX);
  const width = Math.sqrt(distX*distX + distY*distY);
  return { center, angle, width };
}

export function analyzeLandmarks(width: number, height: number, landmarks : Landmarks) : LandmarksAnalyzed {
  let l = landmarks.landmarks;
  return { leftEar : l[1] // l[0]
         , rightEar : l[15] // l[16]
         , leftEyeCenter : findCenter(width, height, l.slice(36, 42))
         , rightEyeCenter : findCenter(width, height, l.slice(42, 48))
         , mouthTopPoint : l[51]
         , mouthLowPoint : l[57]
         , mouthLeftPoint : l[48]
         , mouthRightPoint : l[54]
         , noseTip : l[35]
         , noseRoot : l[27]
         , leftEyeBrow: [ l[17], l[21] ]
         , rightEyeBrow: [ l[21], l[26] ]
         , chinPoint : l[8]
         };
}

/* const RIGHT_EAR = 16;
const LEFT_EAR = 0;
const CHIN_START = 1;
const CHIN_END = 15;
const TOP_LIP_START = 48;
const TOP_LIP_END = 54;
const INNER_TOP_LIP_START = 60;
const INNER_TOP_LIP_END = 64;
const LOW_LIP_START = 55;
const LOW_LIP_END = 59;
const INNER_LOW_LIP_START = 65;
const INNER_LOW_LIP_END = 67;
const NOSE_LINE_START = 31;
const NOSE_LINE_END = 35;
const NOSE_FORM_START = 27;
const NOSE_FORM_END = 30;
const LEFT_EYERBROW_START = 17;
const LEFT_EYERBROW_END = 21;
const RIGHT_EYERBROW_START = 22;
const RIGHT_EYERBROW_END = 26;
const LEFT_EYE_START = 36;
const LEFT_EYE_END = 41;
const RIGHT_EYE_START = 42;
const RIGHT_EYE_END = 47;


export function groupLandmarks(landmarks : Landmarks) : LandmarksGrouped {
  let l = landmarks.landmarks;
  return { leftEar : l[LEFT_EAR]
         , rightEar : l[RIGHT_EAR]
         , chin : l.slice(CHIN_START, CHIN_END)
         , topLip : l.slice(TOP_LIP_START, TOP_LIP_END)
         , topInnerLip : l.slice(TOP_LIP_START, TOP_LIP_END)
         };
} */
