import { MappedAttributes } from './Attributes'

const HAIR_MALE_VAR_COUNT = 4;
const HAIR_FEMALE_VAR_COUNT = 4;
const NOSE_ALT_COUNT = 3;
const EYE_ALT_COUNT = 3;

/* interface Default {
    default: 'default';
}
interface Alt {
    alt: number;
}

type Picked
    = Default
    | Alt */

export type Files =
    { face : string
    , hair: string
    , nose : string
    , eye : string
    , eyeBrow : string
    , hairColor : string
    , additions :
        { bags : boolean
        , doubleChin : boolean
        , eyeglasses : boolean
        , goatee : boolean
        , mustache : boolean
        }
    }


export const defaultFiles : Files =
    { face : 'faceShape'
    , hair : 'hair_male_default'
    , eye : `eye_default`
    , eyeBrow : 'eyebrow_default'
    , nose : 'nose_default'
    , hairColor : 'black'
    , additions :
        { bags: false
        , doubleChin : false
        , eyeglasses : false
        , goatee : false
        , mustache : false
        }

}

/* function pickOne(limit: number) : Picked {
    const val = Math.floor(Math.random() * (limit + 3));
    if (val > limit) return { default:  'default' };
    return { alt: val };
} */

function pickOne(limit: number) : string {
    const val = Math.floor(Math.random() * (limit + 3));
    if (val >= (limit - 1.1)) return 'default';
    return 'alt' + (val + 1);
}

export function combine(a: MappedAttributes) : Files {
    const hairFile = (a.Male > 0.5) ? 'hair_male_' + pickOne(HAIR_MALE_VAR_COUNT) : 'hair_female_' + pickOne(HAIR_FEMALE_VAR_COUNT);

    let noseFile = a.Pointy_Nose > 0.5 ? 'Pointy_Nose' : '';
    noseFile = a.Big_Nose > 0.5 ? 'Big_Nose' : noseFile;
    noseFile = (noseFile == '') ? 'nose_' + pickOne(NOSE_ALT_COUNT) : noseFile;

    let eyeFile = a.Attractive > 0.5 ? 'Attractive' : '';
    //eyeFile = a.Bags_Under_Eyes > 0.5 ? 'Bags_Under_Eyes' : eyeFile;
    eyeFile = (eyeFile == '') ? 'eye_' + pickOne(EYE_ALT_COUNT) : eyeFile;

    let eyeBrowFile = a.Bushy_Eyebrows > 0.5 ? 'Bushy_Eyebrows' : '';
    eyeBrowFile = a.Arched_Eyebrows > 0.5 ? 'Arched_Eyebrows' : eyeBrowFile;
    eyeBrowFile = (eyeBrowFile == '') ? 'eyebrow_default' : eyeBrowFile;

    let faceFile = a.Double_Chin > 0.5 ? 'Double_Chin' : 'faceShape';

    let hairColor = a.Blond_Hair > 0.5 ? '#F2E123' : '';
    hairColor = a.Black_Hair > 0.5 ? '#000' : hairColor;
    hairColor = (hairColor == '') ? '#7B4A00' : hairColor;

    return {
        face : faceFile,
        hair : hairFile,
        hairColor : hairColor,
        nose : noseFile,
        eye : eyeFile,
        eyeBrow : eyeBrowFile,
        additions :
            { bags : a.Bags_Under_Eyes > 0.5
            , eyeglasses : a.Eyeglasses > 0.5
            , doubleChin : a.Double_Chin > 0.5
            , mustache : a.Mustache > 0.5
            , goatee : a.Goatee > 0.5
            }
    }
};
