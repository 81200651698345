import React, { Fragment, useRef, useState, useEffect, Ref } from 'react';
import ReactDOM from 'react-dom';
import { Camera } from 'react-cam';
import DatGui, { DatBoolean, DatNumber, DatFolder } from 'react-dat-gui';

import Face from './Face';
import { State, defaultState } from './State';
import { Service } from './Transfer/Service';
import { Attributes, MappedAttributes, allKeys, defaultMapped, loadAttributes, mapAttributes } from './Transfer/Attributes';
import { Landmarks } from './Transfer/Landmarks';
import { ImageRequest } from './Transfer/ImageRequest';
import { Files, defaultFiles, combine } from './Transfer/Files';
import SERVER_URL from './Transfer/ServerUrl';

import io from 'socket.io-client';

const socket = io(SERVER_URL);

const RECEIVE_ATTRIBUTES_AT = 'attributes';
const RECEIVE_LANDMARKS_AT = 'landmarks';
const SEND_IMAGE_TO_GET_ATTRIBUTES = 'get_face_attributes';
const SEND_IMAGE_TO_GET_LANDMARKS = 'get_face_landmarks';

const App = () => {
  const cam = useRef(null);
  const [image, setImage] = useState('');
  const [gotSocketAttributes, setGotSocketAttributes] = useState(false);
  const [socketAttributes, setSocketAttributes] = useState<Service<Attributes>>({
    status: 'loading'
  });
  const [mappedAttributes, setMappedAttributes] = useState<MappedAttributes>(defaultMapped());
  const [landmarks, setLandmarks] = useState<Service<Landmarks>>({
    status: 'loading'
  });
  const [files, setFiles] = useState<Files>(defaultFiles);
  const [appState, setAppState] = useState<State>(defaultState);

  const updateState = (s : any) => {
    setAppState({
      ...appState
      , attributes: loadAttributes(s.attributes)
      , width : s.width
      , height : s.height
      , showPoints : s.showPoints
      , showCamera : s.showCamera
      , showAvatar : s.showAvatar
      , focusWidth : s.focusWidth
      , focusHeight : s.focusHeight
      , refreshPeriod : s.refreshPeriod
    });
    setFiles(combine(s.attributes));
    setMappedAttributes(s.attributes);
  };

  useEffect(() => {
    socket.on(RECEIVE_LANDMARKS_AT, (response : Landmarks) => {
      setLandmarks({ status: 'loaded', payload: response });
    });
  }, []); //only re-run the effect if new message comes in

  useEffect(() => {
    socket.on(RECEIVE_ATTRIBUTES_AT, (response : Attributes) => {
      setSocketAttributes({ status: 'loaded', payload: response });
      const newMappedAttributes_ = mapAttributes(response)
      setMappedAttributes(newMappedAttributes_);
      setFiles(combine(newMappedAttributes_));
      setAppState({
        ...appState
        , attributes : newMappedAttributes_
      });
      setGotSocketAttributes(true);
    });
  }, [ socketAttributes ]); //only re-run the effect if new message comes in

  useEffect(() => {
    const body : ImageRequest = { image_string : image };

    socket.emit(SEND_IMAGE_TO_GET_LANDMARKS, body);

    if (!gotSocketAttributes) {
      socket.emit(SEND_IMAGE_TO_GET_ATTRIBUTES, body);
    }
  }, [ image, gotSocketAttributes ]);

  let requestRef : any = React.useRef();
  let prevCaptureRef : any = React.useRef();
  prevCaptureRef.current = 0;

  const animate = (time : number) => {
    if (prevCaptureRef.current != undefined) {
      if ((time - prevCaptureRef.current) > appState.refreshPeriod) {
        cam.current.capture();
        prevCaptureRef.current = time;
      }
    } else {
      prevCaptureRef.current = time;
    }

    requestRef.current = requestAnimationFrame(animate);
  }

  const saveFile = () => {
    const elHtml = document.getElementById('avatar').innerHTML;
    const link = document.createElement('a');

    link.setAttribute('download', 'avatar.svg');
    link.setAttribute('href', 'data:' + 'image/svg+xml'  +  ';charset=utf-8,' + encodeURIComponent(elHtml));
    link.click();
  }

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []); // Make sure the effect runs only once

  return (
    <Fragment>
      { /* <div>{Math.round(count)}</div> */ }

      <div className="abs">
        {landmarks.status === 'loading' && <div>Loading...</div>}
        {landmarks.status === 'loaded' &&
          (<Face
              landmarks={landmarks.payload} attributes={mappedAttributes}
              files={files}
              showPoints={appState.showPoints}
              showCamera={appState.showCamera}
              showAvatar={appState.showAvatar}
              width={appState.width}
              height={appState.height}
            />)
        }
        {landmarks.status === 'error' && (
          <div>Error, the backend moved to the dark side.</div>
        )}
      </div>

      <Camera
        className="abs"
        showFocus={true}
        front={true}
        capture={(imgSrc : string) => setImage(imgSrc)}
        ref={cam}
        width={`${appState.width}px`}
        height={`${appState.height}px`}
        focusWidth={`${appState.focusWidth * 100}%`}
        focusHeight={`${appState.focusHeight * 100}%`}
        style={`visibility: ${appState.showCamera ? 'hidden' : 'visible'}`}
        btnColor="white"
      />
      <button onClick={img => cam.current.capture(img)}>Take image</button>

      <button onClick={() => saveFile()} className="abs" id="downloadLink">Download the avatar</button>

      <h1 className="abs">PUBLO</h1>

      <DatGui data={appState} onUpdate={updateState}>
        <DatBoolean path="showPoints" label="Show Points" />
        <DatBoolean path="showCamera" label="Show Camera" />
        <DatBoolean path="showAvatar" label="Show Avatar" />
        <DatNumber path='focusWidth' label='Focus Width' min={0.0} max={1.0} step={0.01} />
        <DatNumber path='focusHeight' label='Focus Height' min={0.0} max={1.0} step={0.01} />
        <DatNumber path='refreshPeriod' label='Refresh Period' min={1000} max={10000} step={100} />
        <DatNumber path='width' label='Width' min={512} max={2048} step={16} />
        <DatNumber path='height' label='Height' min={512} max={2048} step={16} />
        { /* <DatFolder title="attributes" closed={true}> */ }
          { allKeys.map(keyName =>
              <DatNumber
                key={keyName}
                path={`attributes.${keyName}`}
                label={keyName} min={0.0} max={1.0} step={0.01}
              />
            )
          }
        { /* </DatFolder> */ }
      </DatGui>

    </Fragment>
  );
};
ReactDOM.render(<App />, document.getElementById('root'));

export default App;
